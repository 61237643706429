<template>
  <Layout>
    <!--==================================================-->
    <!-- Start lawyern breatcam section  -->
    <!--==================================================-->
    <div class="breatcam-section d-flex align-items-center">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="breatcam-content text-center">
              <!-- breadcumb title -->
              <div class="breatcam-title">
                <h1> {{ $t('header.nav.join') }} </h1>
              </div>
              <!-- breatcam menu -->
<!--              <div class="breatcam-menu">-->
<!--                <ul>-->
<!--                  <li><a @click="$router.push('/')"> {{ $t('header.nav.home') }} </a></li>-->
<!--                  <li> {{ $t('header.nav.join') }}</li>-->
<!--                </ul>-->
<!--              </div>-->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--==================================================-->
    <!-- End lawyern breatcam section -->
    <!--==================================================-->


    <!--==================================================-->
    <!-- Start lawyer service section  -->
    <!--==================================================-->

    <div class="service-section">
      <div class="container">
            <div class="join-detail-single-box">
              <div class="join-detail-title"><span>{{ info.position }}</span> <i class="bi bi-geo-alt"></i>{{info.city}}</div>
              <div class="join-detail-content">
                <div v-html="info.desc">{{ info.desc }}</div>
              </div>
            </div>
      </div>
    </div>

    <!--==================================================-->
    <!-- End lawyer service Section -->
    <!--==================================================-->
  </Layout>
</template>

<script>
import Layout from '@/components/common/Layout'

export default {
  name: 'Demo',
  components: {
    Layout},
  data() {
    return {
      id: '',
      info: {
      }
    }
  },
  created() {
    this.id = this.$route.query.id;
    this.getData();
  },
  methods: {
    getData() {
      this.$request({
        url: 'api/pc/recruit-detail',
        data: {id: this.id}
      }).then(res => {
        this.info = res.data;
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.breatcam-section {
  background-image: url(~@/assets/scss/page/image/join/bg.jpg);
}
.service-section {
  background: url(~@/assets/scss/page/image/join/bg-3.jpg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  min-height: 800px;
}
.join-detail-single-box{
  .join-detail-title{
    font-size: 18px;
    padding-bottom: 20px;
    border-bottom: 1px solid #D1D1D1;
    span{
      font-size: 24px;
      font-weight: 600;
      margin-right: 15px;
    }
    i{
      margin-right: 5px;
    }
  }
  .join-detail-content{
    padding: 30px 0;
  }
}
</style>
