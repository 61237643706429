<template>
  <Layout>
    <!--==================================================-->
    <!-- Start lawyern breatcam section  -->
    <!--==================================================-->
    <div class="breatcam-section d-flex align-items-center">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="breatcam-content text-center">
              <div class="detail-left" data-aos="fade-up" data-aos-delay="100">
                <img :src="info.cover_pic">
              </div>
              <div class="detail-right" data-aos="fade-up" data-aos-delay="100">
                <div class="detail-name">{{ info.name }} <span><i class="bi bi-geo-alt"></i> {{ info.address }}</span>
                </div>
                <div class="detail-tag">
                  <div>{{ info.job }}</div>
                  <div v-for="item in info.domain">{{ item }}</div>
                </div>
                <div class="detail-bottom">
                  <div class="detail-info">
                    <div><i class="bi bi-printer-fill"></i>{{ info.phone || '--' }}</div>
                    <div><i class="bi bi-telephone-fill"></i>{{ info.mobile || '--' }}</div>
                    <div><i class="bi bi-envelope-fill"></i>{{ info.email || '--' }}</div>
                    <div><i class="bi bi-geo-alt-fill"></i>{{ info.office.address || '--' }}</div>
                  </div>
                  <div class="detail-btns">
                    <i v-if="info.qrcode" class="bi bi-qr-code" @click="dialogVisible = true"></i>
                    <div v-if="info.vitae" class="lawyer-button text-center">
                      <a @click="downloadFile"><i class="bi bi-download"></i> {{ $t('page.lawyer.text3') }}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--==================================================-->
    <!-- End lawyern breatcam section -->
    <!--==================================================-->


    <!--==================================================-->
    <!-- Start lawyer service section  -->
    <!--==================================================-->
    <div>
      <div class="container-fluid">
        <div class="container">
          <div class="container-left">
            <div class="detail-menu-item" :class="index===currentTag?'active':''"
                 v-for="(item,index) in info.pc_detail" @click="tagSelect(index)">
              {{ item.label }}
            </div>
          </div>
          <div class="container-right">
            <div class="detail-item" v-for="(item,index) in info.pc_detail" :id="`detail${index}`">
              <div class="detail-item-title">{{ item.label }}</div>
              <div v-html="item.content">{{ item.content }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--==================================================-->
    <!-- End lawyer service Section -->
    <!--==================================================-->

    <el-dialog :visible.sync="dialogVisible" width="640px" center>
      <div class="code-image text-center" >
        <img :src="info.qrcode" style="width: 480px">
      </div>
    </el-dialog>
  </Layout>
</template>

<script>
import Layout from '@/components/common/Layout'
import {Dialog} from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

export default {
  name: 'Demo',
  components: {
    Layout,
    ElDialog: Dialog
  },
  data() {
    return {
      id: '',
      currentTag: 0,
      info: {
        office:{},
        qrcode: ''
      },
      dialogVisible: false,
    }
  },
  created() {
    this.id = this.$route.query.id;
    this.getData();
  },
  methods: {
    getData() {
      this.$request({
        url: 'api/pc/lawyer-detail',
        data: {id: this.id}
      }).then(res => {
        this.info = res.data
      })
    },
    tagSelect(index) {
      this.currentTag = index;
      const anchorElement = document.getElementById(`detail${index}`);
      if (anchorElement) {
        anchorElement.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'nearest'
        });
      }
    },
    downloadFile() {
      // 创建一个隐藏的 <a> 标签
      const link = document.createElement('a');
      link.href = this.info.vitae;
      link.target = '_blank';
      link.download = `${this.info.name}律师简历.pdf`; // 设置文件名
      link.style.display = 'none';

      // 将 <a> 标签添加到 DOM 中
      document.body.appendChild(link);

      // 触发点击事件
      link.click();

      // 从 DOM 中移除 <a> 标签
      document.body.removeChild(link);
      // fetch(this.info.vitae)
      //     .then(response => {
      //       if (!response.ok) {
      //         throw new Error('Network response was not ok');
      //       }
      //       return response.blob();
      //     })
      //     .then(blob => {
      //       // 创建一个 blob URL
      //       const url = URL.createObjectURL(blob);
      //       // 创建一个隐藏的 <a> 标签
      //       const link = document.createElement('a');
      //       link.href = url;
      //       link.target = '_blank';
      //       link.download = `${this.info.name}律师简历.pdf`; // 设置文件名
      //       link.style.display = 'none';
      //
      //       // 将 <a> 标签添加到 DOM 中
      //       document.body.appendChild(link);
      //
      //       // 触发点击事件
      //       link.click();
      //
      //       // 清理 blob URL
      //       URL.revokeObjectURL(url);
      //
      //       // 从 DOM 中移除 <a> 标签
      //       document.body.removeChild(link);
      //     })
      //     .catch(error => {
      //       console.error('Error downloading file:', error);
      //     });
    }
  }
}
</script>
<style lang="scss" scoped>
.breatcam-section {
  background-image: url(~@/assets/scss/page/image/lawyer/detail-bg.jpg);
  height: 650px;
}

.breatcam-content {
  display: flex;
  padding-top: 260px;
  position: relative;

  .detail-left {
    width: 340px;
    height: 390px;
    overflow: hidden;

    img {
      height: 390px;
      margin-left: -5px;
    }
  }

  .detail-right {
    flex: 1;
    //padding-top: 68px;
    color: white;
    text-align: left;
    //margin-left: 68px;
    font-size: 14px;
    overflow: hidden;
    position: absolute;
    bottom: 10px;
    left: 408px;
    width: calc(100% - 408px);

    .detail-name {
      font-size: 32px;
      font-weight: 600;
      margin-bottom: 24px;

      span {
        font-size: 14px;
        margin-left: 20px;
      }
    }

    .detail-tag {
      display: flex;
      flex-wrap: wrap;
      align-items: center;

      div {
        min-width: 160px;
        padding: 3px 20px;
        border: 1px solid rgba(255, 255, 255, 0.5);
        border-radius: 4px;
        text-align: center;
        margin-right: 10px;
        margin-bottom: 5px;
      }
    }

    .detail-bottom {
      display: flex;
      align-items: flex-end;
      font-size: 18px;
      margin-top: 20px;

      .detail-info {
        flex: 1;

        div {
          margin-top: 15px;
          line-height: 1.2;

          i {
            margin-right: 18px;
          }
        }
      }

      .detail-btns {
        display: flex;
        align-items: center;

        > i {
          font-size: 50px;
          margin-top: 26px;
          margin-right: 20px;
        }

        .lawyer-button a i {
          margin: 0 5px 0 0;
        }
      }
    }
  }
}

.container-fluid {
  background: linear-gradient(to right, #F7F7FB 50%, #ffffff 50%);

  .container {
    display: flex;

    .container-left {
      padding-top: 70px;
      width: 340px;

      .detail-menu-item {
        height: 70px;
        line-height: 70px;
        text-align: center;
        font-size: 18px;
        cursor: pointer;
      }

      .detail-menu-item.active {
        color: white;
        background: var(--color-main);
      }
    }

    .container-right {
      padding: 70px 0;
      flex: 1;
      background: white;
      min-height: 800px;

      .detail-item {
        padding-left: 70px;
        margin-bottom: 50px;

        .detail-item-title {
          font-size: 20px;
          color: var(--color-main);
          font-weight: bold;
          line-height: 40px;
          border-bottom: 1px solid #e6e6e6;
          margin-bottom: 15px;
        }
      }
    }
  }


}
</style>
