<template>
  <Layout>
    <!--==================================================-->
    <!-- Start lawyern breatcam section  -->
    <!--==================================================-->
    <div class="about breatcam-section d-flex align-items-center">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="breatcam-content text-center">
              <!-- breadcumb title -->
              <div class="breatcam-title">
                <h1> {{ $t('page.about.title') }} </h1>
              </div>
              <!-- breatcam menu -->
<!--              <div class="breatcam-menu">-->
<!--                <ul>-->
<!--                  <li><a @click="$router.push('/')"> {{ $t('page.about.home') }} </a></li>-->
<!--                  <li> {{ $t('page.about.title') }} </li>-->
<!--                </ul>-->
<!--              </div>-->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--==================================================-->
    <!-- End lawyern breatcam section -->
    <!--==================================================-->






    <!--==================================================-->
    <!-- Start lawyer about Section -->
    <!--==================================================-->
    <div class="about-section style-four">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-5 col-md-12">
            <!-- about thumb -->
            <div class="about-thumb3">
              <img src="@/assets/scss/page/image/about/img-1.jpg" alt="">
            </div>
          </div>
          <div class="col-lg-7 col-md-12">
            <div class="lawyer-section-title">
              <div><h1 class="about-border2"> {{ $t('page.about.text1') }} </h1></div>
              <h1> {{ $t('page.about.text2') }}</h1>
              <p class="about-pt"><pre>{{ info.about_us_intro }}</pre></p>
            </div>
          </div>
        </div>

        <div class="row align-items-center mt-150">
          <div class="col-lg-6 col-md-12">
            <div class="lawyer-section-title">
              <div><h1 class="about-border2"> <h4>{{ $t('page.about.text4') }}</h4></h1></div>
              <h1> {{ $t('page.about.text3') }}</h1>
              <p class="about-pt"><pre>{{ info.about_team_qualification }}</pre></p>
            </div>
          </div>
          <div class="col-lg-6 col-md-12">
            <!-- about thumb -->
            <div class="about-thumb3 text-right">
              <img src="@/assets/scss/page/image/about/img-2.jpg" alt="">
            </div>
          </div>
        </div>

        <div class="row align-items-center mt-60">
          <div class="col-lg-6 col-md-12">
            <div class="lawyer-section-title">
              <div><h1 class="about-border2"> <h4>{{ $t('page.about.text6') }}</h4></h1></div>
              <h1> {{ $t('page.about.text5') }}</h1>
              <p class="about-pt"><pre>{{ info.about_profession_work }}</pre></p>
            </div>
          </div>
          <div class="col-lg-6 col-md-12">
            <!-- about thumb -->
            <div class="about-thumb3 text-right">
              <img src="@/assets/scss/page/image/about/img-3.jpg" alt="">
            </div>
          </div>
        </div>

        <div class="row align-items-center mt-60">
          <div class="col-lg-6 col-md-12">
            <div class="lawyer-section-title">
              <div><h1 class="about-border2"> <h4>{{ $t('page.about.text8') }}</h4></h1></div>
              <h1> {{ $t('page.about.text7') }}</h1>
              <p class="about-pt"><pre>{{ info.about_aim }}</pre></p>
            </div>
          </div>
          <div class="col-lg-6 col-md-12">
            <!-- about thumb -->
            <div class="about-thumb3 text-right">
              <img src="@/assets/scss/page/image/about/img-4.jpg" alt="">
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--==================================================-->
    <!-- End lawyer about Section -->
    <!--==================================================-->
  </Layout>
</template>

<script>
import Layout from '@/components/common/Layout'


export default {
  name: 'Demo',
  components: {Layout},
  data() {
    return {
      info: {
        about_aim: '',
        about_us_intro: '',
        about_team_qualification: '',
        about_profession_work: ''
      }
    }
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      this.$request({
        url: 'api/pc/about-us',
        data: ''
      }).then(res => {
        this.info = res.data;
      })
    }
  }
}
</script>
