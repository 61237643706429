import Vue from 'vue'
import App from "@/App.vue";
import router from '@/router'
import 'bootstrap/scss/bootstrap.scss'
import 'animate.css'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
import 'bootstrap'
import 'bootstrap-icons/font/bootstrap-icons.css'
import '@/assets/scss/style.scss'

import CoolLightBox from 'vue-cool-lightbox'
import VueScrollActive from 'vue-scrollactive'
import AOS from 'aos'
import 'aos/dist/aos.css'

Vue.config.productionTip = false
Vue.use(CoolLightBox)
Vue.use(VueScrollActive)

import VueI18n from 'vue-i18n';
import zhMessages from '@/assets/lang/zh.js';
import enMessages from '@/assets/lang/en.js';

Vue.use(VueI18n);

Vue.prototype.$localeLang = Cookies.get('localeLang') || 'zh';

import {getConnect} from "@/api/request";
import Cookies from "js-cookie";
Vue.prototype.$request = getConnect;

const i18n = new VueI18n({
  locale: Cookies.get('localeLang') || 'zh',
  messages: {
    zh: zhMessages,
    en: enMessages
  }
});

new Vue({
  router,
  i18n,
  created () {
    AOS.init({
      duration: 800,
      once: true,
    })
  },
  render: h => h(App)
}).$mount('#app')
