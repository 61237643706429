export default {
  "title": "广东君言律师事务所",
  'all': "全部",
  header: {
    nav: {
      home: "首页",
      businessAreas: "业务领域",
      lawyerTeam: "律师团队",
      news: "新闻动态",
      about: "关于我们",
      join: "加入我们",
      contact: "联系我们",
      junYanNews: "君言动态",
      honors: '君言荣誉',
      perspectives: "君言观点",
      renwen: '君言人文',
      lectureHall: "君言讲堂",
      achievements: "君言业绩"
    }
  },
  footer: {
    phone: '电话：',
    email: '邮箱：',
    copyright: '版权所有',
    copyrightText: 'Copyright © 2024 JunYan Law Firm. All Rights Reserved 广东君言律师事务所 版权所有 粤ICP备14017639号',
    businessAreas: '业务领域',
    news: '君言新闻',
    about: '关于君言',
    劳动与就业: '劳动与就业',
    知识产权: '知识产权',
    法律IT服务: '法律IT服务',
    银行和金融: '银行和金融',
    民商事争议解决: '民商事争议解决',
    刑事辩护与刑事合规: '刑事辩护与刑事合规',
    房地产与建筑工程: '房地产与建筑工程',
    婚姻家事与财富管理: '婚姻家事与财富管理',
    新闻资讯: '新闻资讯',
    君言业绩: '君言业绩',
    君言荣誉: '君言荣誉',
    君言动态: '君言动态',
    君言讲堂: '君言讲堂',
    君言观点: '君言观点',
    君言人文: '君言人文',
    君言简介: '君言简介',
    加入君言: '加入君言'
  },
  发现更多: '发现更多',
  了解更多: '了解更多',
  查看更多: '查看更多',
  业务领域: '业务领域',
  专业团队: '专业团队',
  最新资讯: '最新资讯',
  荣誉奖项: '荣誉奖项',
  全国分所: '全国分所',
  page:{
    home: {
      了解更多: '了解更多',
      查看更多: '查看更多',
      text1: '欢迎来到君言律师事务所',
      text2: '君言律师事务所于2003年5月26日经广东省司法厅批准设立。是由数位志同道合的资深律师、专家学者发起成立的一家高起点、高标准的律师事务所。本所现有执业律师、律师助理、行政秘书200余人，办公面积2000余平方米。本所被北京大学法学院、西南政法大学确定为教学实习基地。',
      text3: '业务领域',
      text4: 'Practice Areas',
      text5: '深耕法律前沿，横跨民事、商事、刑事等多元领域，以专业铸就卓越，为客户提供全方位、定制化的法律解决方案。',
      text6: '专业团队',
      text7: 'Professional Team',
      text8: '汇聚精英智慧，共创法律辉煌 —— 我们的团队，您的信赖之选',
      text9: '按名称搜索',
      text10: '按办公地点搜索',
      text11: '按业务领域搜索',
      text12: '最新资讯',
      text13: 'Latest News',
      text14: '洞察法治前沿，掌握律政脉动 —— 最新资讯，为您呈现行业动态与专业视角',
      text15: '荣誉奖项',
      text16: 'Honors & Awards',
      text17: '卓越见证，荣耀加冕 —— 我们的专业与承诺，屡获业界殊荣',
      text18: '全国分所',
      text19: 'National Offices',
      text20: '跨越地域，服务全国 —— 分所遍布各地，专业触手可及',
      text21: '组织介绍',
      text22: 'About Us',
      text23: '根植信念，构筑未来 —— 深入了解我们的组织架构与文化，共同见证法律服务的力量与价值',
      text24: '团队资质',
      text25: '君言律师事务所是一家位于深圳市南山高新科技园区的大型综合性律师事务所。经过18年的发展，已形成具有良好执业背景及丰...',
      text26: '专业分工',
      text27: '君言律师事务所成立之初就立足于为客户提供精品法律服务，通过18年的实践，君言的律师也已形成了居于行业的专业化分工...',
      text28: '君言宗旨',
      text29: '“专注、务实、诚信、高效”是君言律师事务所的服务宗旨，“专业化分工，团队化运作、整体化服务”是君言律师事务所的服...'
    },
    about: {
      title: '关于君言',
      home: '首页',
      text1: '欢迎来到',
      text2: '君言律师事务所',
      text3: '团队资质',
      text4: 'TEAM QUALIFICATION',
      text5: '专业分工',
      text6: 'PROFESSIONAL DIVISION',
      text7: '君言宗旨',
      text8: 'JUNYAN PRINCIPLE',
    },
    lawyer: {
      text1: '律师团队',
      text2: 'LAWYER TEAM',
      text3: '下载简历',
      text4: '输入律师名称搜索',
      text5: '按办公地点筛选',
      text6: '按业务领域筛选'
    },
    news: {
      text1: '相关业务领域',
      text2: '相关人士',
      text3: '上一页：',
      text4: '返回列表',
      text5: '查看详情'
    },
    join: {
      text1: '搜索工作岗位',
      text2: '按城市筛选',
      text3: '按职位类被筛选',
      text4: '按招聘类型筛选',
      text5: '更新于 ',
    }
  }
}
