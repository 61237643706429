<template>
  <div></div>
</template>

<script>
    export default {
        name: 'MobileMenu',
        components: {},
        data() {
            return {

            }
        },
        mounted() {

        }
    }
</script>
