<template>
  <Layout>
    <!--==================================================-->
    <!-- Start lawyern breatcam section  -->
    <!--==================================================-->
    <div class="breatcam-section d-flex align-items-center">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="breatcam-content text-center">
              <!-- breadcumb title -->
              <div class="breatcam-title">
                <h1> {{ $t('header.nav.news') }} </h1>
              </div>
              <!-- breatcam menu -->
<!--              <div class="breatcam-menu">-->
<!--                <ul>-->
<!--                  <li><a @click="$router.push('/')"> {{ $t('header.nav.home') }} </a></li>-->
<!--                  <li> {{ $t('header.nav.news') }}</li>-->
<!--                </ul>-->
<!--              </div>-->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--==================================================-->
    <!-- End lawyern breatcam section -->
    <!--==================================================-->


    <!--==================================================-->
    <!-- Start lawyer service section  -->
    <!--==================================================-->
    <div class="tabbar-section">
      <div class="container">
        <div class="tabbar-box">
          <div :class="['tabbar-item', search.cat_id===1?'active': '']" @click="tabbarClick(1)">{{ $t('header.nav.junYanNews' )}}</div>
          <div :class="['tabbar-item', search.cat_id===2?'active': '']" @click="tabbarClick(2)">{{ $t('header.nav.honors' )}}</div>
          <div :class="['tabbar-item', search.cat_id===3?'active': '']" @click="tabbarClick(3)">{{ $t('header.nav.perspectives' )}}</div>
          <div :class="['tabbar-item', search.cat_id===4?'active': '']" @click="tabbarClick(4)">{{ $t('header.nav.renwen')}}</div>
        </div>
      </div>
    </div>

    <div class="service-section">
      <div class="container">
        <el-carousel indicator-position="outside" :height="carouselHeight" :interval="5000">
          <el-carousel-item v-for="(item,index) in topList" :key="index">
            <div class="top-news-single-box">
              <div class="top-news-img">
                <img :src="item.cover_pic">
              </div>
              <div class="top-news-content">
                <div class="top-news-title">{{ item.title }}</div>
                <div class="top-news-time">{{ item.month }}</div>
                <div class="top-news-subtitle">{{ item.sub_title }}</div>
                <div class="top-news-btns">
                  <div class="top-news-detail-btn" @click="$router.push(`/news-detail?id=${item.id}`)">{{$t('page.news.text5')}}</div>
                </div>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>

        <div class="mt-60 news-content">
          <div class="news-single-box" v-for="(item, index) in list" :key="index" @click="$router.push(`/news-detail?id=${item.id}`)">
            <div class="news-time">
              <span>{{ item.month }}</span> {{ item.year }}
            </div>
            <div class="news-title">{{ item.title }}</div>
            <div class="news-subtitle">{{ item.sub_title }}</div>
            <div class="news-btns">
              <div class="news-detail-btn"><i class="bi bi-arrow-right"></i></div>
            </div>
          </div>
        </div>

        <div class="text-center">
          <el-pagination
              layout="prev, pager, next"
              :current-page="search.page"
              :page-size="search.limit"
              @current-change="handleCurrentChange"
              :total="total">
          </el-pagination>
        </div>
      </div>
    </div>

    <!--==================================================-->
    <!-- End lawyer service Section -->
    <!--==================================================-->
  </Layout>
</template>

<script>
import Layout from '@/components/common/Layout'
import {Carousel as ElCarousel, CarouselItem as ElCarouselItem, Pagination as ElPagination} from 'element-ui';

export default {
  name: 'Demo',
  components: {ElPagination, Layout, ElCarousel, ElCarouselItem},
  data() {
    return {
      locateImg: require('@/assets/scss/page/image/common/locate.png'),
      carouselHeight: '445px',
      search: {
        cat_id: 1,
        page: 1,
        limit: 6,
      },
      topList: [],
      list: [],
      total: 0,
    }
  },
  mounted() {
    this.search.cat_id = this.$route.query.type?Number(this.$route.query.type) : 1;
    this.getData();
    // 根据container宽度计算carouselHeight，carouselHeight = contain宽度 / 2.96
    this.carouselHeight = (document.querySelector('.container').offsetWidth / 2.96) + 'px';
    window.onresize = () => {
      this.carouselHeight = (document.querySelector('.container').offsetWidth / 2.96) + 'px';
    }
  },
  methods: {
    tabbarClick(index) {
      this.search.cat_id = index;
      this.getData();
    },
    getData() {
      this.$request({
        url: 'api/pc/article-list',
        data: this.search
      }).then(res => {
        this.topList = res.data.top;
        this.list = res.data.list;
        this.total = res.data.pagination.totalCount;
      })
    },
    handleCurrentChange(e) {
      this.search.page = e;
      this.getData();
    }
  }
}
</script>
<style lang="scss" scoped>
.breatcam-section {
  background-image: url(~@/assets/scss/page/image/news/bg.jpg);
}

.tabbar-section {
  margin-top: -60px;

  .tabbar-box {
    width: 100%;

    background: rgba(22, 22, 22, 0.5);
    color: white;
    display: flex;
    align-items: center;

    .tabbar-item {
      flex: 1;
      text-align: center;
      height: 60px;
      line-height: 60px;
      cursor: pointer;
    }

    .tabbar-item.active {
      background-color: var(--color-main);
    }
  }
}

.top-news-single-box {
  width: 100%;
  height: 100%;
  display: flex;

  .top-news-img {
    width: 62%;
    height: 100%;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .top-news-content {
    width: 38%;
    height: 100%;
    background: #F7F7FB;
    padding: 50px;
    position: relative;
    overflow: hidden;

    .top-news-title {
      font-size: 28px;
      color: #191514;
      line-height: 1.4;
    }

    .top-news-time {
      font-size: 20px;
      color: #555555;
      margin: 16px 0;
    }

    .top-news-subtitle {
      font-size: 20px;
    }

    .top-news-btns {
      position: absolute;
      bottom: 50px;
      left: 50px;

      .top-news-detail-btn {
        width: 142px;
        height: 48px;
        line-height: 48px;
        border-radius: 4px;
        background: var(--color-main);
        text-align: center;
        color: white;
        cursor: pointer;
      }
    }
  }
}

.news-content {
  width: calc(100% + 50px);
  display: flex;
  flex-wrap: wrap;
}

.news-single-box {
  width: calc(50% - 50px);
  font-size: 20px;
  min-height: 312px;
  padding: 40px 30px;
  border-bottom: 1px solid #E6E6E6;
  margin: 0 50px 30px 0;
  line-height: 1.4;
  position: relative;
  cursor: pointer;

  .news-time {
    span {
      font-size: 30px;
    }
  }

  .news-title {
    font-size: 24px;
    font-weight: 600;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    margin: 10px 0 15px 0;
  }

  .news-subtitle {

  }

  .news-btns {
    position: absolute;
    bottom: 40px;
    left: 30px;
  }

  &:hover {
    background-color: var(--color-main);
    color: white;
    border-radius: 4px;
  }
}

@media (min-width: 992px) and (max-width: 1280px) {
  .top-news-single-box {
    .top-news-content {
      padding: 20px;

      .top-news-title {
        font-size: 22px;
        line-height: 1.2;
      }

      .top-news-time {
        font-size: 16px;
        margin: 10px 0;
      }

      .top-news-subtitle {
        font-size: 16px;
      }

      .top-news-btns {
        bottom: 20px;
        left: 20px;

        .top-news-detail-btn {
          width: 142px;
          height: 48px;
          line-height: 48px;
          cursor: pointer;
        }
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .top-news-single-box {
    .top-news-content {
      padding: 30px;

      .top-news-title {
        font-size: 20px;
        line-height: 1.2;
      }

      .top-news-time {
        font-size: 14px;
        margin: 10px 0;
      }

      .top-news-subtitle {
        font-size: 16px;
      }

      .top-news-btns {
        bottom: 30px;
        left: 30px;

        .top-news-detail-btn {
          width: 142px;
          height: 48px;
          line-height: 48px;
          cursor: pointer;
        }
      }
    }
  }
}

</style>
