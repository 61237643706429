<template>
  <Layout>
<!--    <div class="demo-page">-->
      <!--==================================================-->
      <!-- Start lawyer banner section -->
      <!--==================================================-->
      <div class="banner-section d-flex align-items-center">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <!-- banner content -->
              <div class="banner-content text-center">
                <!-- banner title -->
                <div class="banner-title" data-aos="fade-up" data-aos-delay="150">
                  <!--							<h1> Every New Time Will  </h1>-->
                  <!--							<h1> Give its Law </h1>-->
                  <h1>心与君同│立言立行</h1>
                </div>
                <!-- banner button -->
                <div class="banner-button" data-aos="fade-up" data-aos-delay="200">
                  <!--							<a href="about.html"> Explore More <i class="bi bi-arrow-right"></i></a>-->
                  <a @click="$router.push('/lawyer')"> 发现更多 <i class="bi bi-arrow-right"></i></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--==================================================-->
      <!--End lawyer banner section  -->
      <!--==================================================-->


      <!--==================================================-->
      <!-- Start lawyer about Section -->
      <!--==================================================-->
      <div class="about-section" data-aos="fade-up" data-aos-delay="200">
        <div class="container">
          <div class="row about-bg">
            <div class="col-lg-6 pr-0 pl-0">
              <div class="about-extra-single-box">
                <div class="lawyer-section-title">
                  <h4> {{ $t('page.home.text1') }} </h4>
                  <p><pre>{{ info.about_us.about_us_intro}}</pre></p>
                </div>
                <!-- lawyer button -->
                <div class="lawyer-button">
                  <a @click="$router.push('/about')"> {{ $t('了解更多') }}... </a>
                </div>
              </div>
            </div>
            <div class="col-lg-6 pl-0 pr-0">
              <!-- about thumb -->
              <div class="about-thumb about-bg-image"></div>
            </div>
          </div>
        </div>
      </div>
      <!--==================================================-->
      <!-- End lawyer about Secton -->
      <!--==================================================-->


      <!--==================================================-->
      <!-- Start lawyer service section 业务领域 -->
      <!--==================================================-->
      <div class="service-section">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="lawyer-section-title pb-60 text-center" data-aos="fade-up">
                <h1>
                  <img src="@/assets/scss/page/image/home/icon-1.png">
                  <span class="chinese-text">{{ $t('page.home.text3') }}</span>
                  <span class="english-text">{{ $t('page.home.text4') }}</span>
                </h1>
                <!--              <p>{{ $t('page.home.text5') }}</p>-->
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-3 col-md-6" v-for="(item, index) in info.domain" :key="index">
              <div class="lawyer-service-single-box" data-aos="fade-up" data-aos-delay="100" data-aos-anchor-placement="top">
                <!-- service icon -->
                <div class="service-icon">
                  <img :src="item.icon || defaultIcon" alt="">
                </div>
                <!-- donate content -->
                <div class="lawyer-service-content">
                  <!-- service title -->
                  <div class="service-title">
                    <h3>{{ item.name }}</h3>
                  </div>
                  <!-- service description -->
                  <div class="service-desc">
                    <p>{{ item.intro }}</p>
                  </div>
                  <!-- service button -->
                  <div class="service-button">
                    <a style="color: var(--color-main);" @click="$router.push(`/service-detail?id=${item.id}`)">{{ $t('page.home.了解更多') }}...</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- lawyer button -->
          <div class="lawyer-button text-center" data-aos="fade-up" data-aos-delay="100">
            <a @click="$router.push('/service')"> {{ $t('page.home.查看更多') }} <i class="bi bi-arrow-right"></i></a>
          </div>
        </div>
      </div>
      <!--==================================================-->
      <!-- End lawyer service Secton -->
      <!--==================================================-->


      <!--==================================================-->
      <!-- Start lawyer Blog Section 团队 -->
      <!--==================================================-->
      <div class="team-section">
        <div class="container" data-aos="fade-up" data-aos-delay="100" data-aos-anchor-placement="top" >
          <div class="row">
            <div class="col-lg-12">
              <div class="lawyer-section-title white text-center pb-65">
                <h1>
                  <img src="@/assets/scss/page/image/home/icon-1.png">
                  <span class="chinese-text">{{ $t('page.home.text6') }}</span>
                  <span class="english-text">{{ $t('page.home.text7') }}</span>
                </h1>
                <!--              <p>{{ $t('page.home.text8') }}</p>-->
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4 col-md-6">
              <div class="team-search">
                <input v-model="search.lawyer" :placeholder="$t('page.home.text9')" @change="searchLawyer">
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="team-select">
                <div class="team-select-value">{{ search.office || $t('page.home.text10') }}<i class="bi bi-chevron-down"></i></div>
                <ul class="sub-menu">
                  <li v-for="(office,idx) in info.lawyer_where.office" :key="idx"
                      @click="searchLawyer({office: office})">{{ office.name }}</li>
                </ul>
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="team-select">
                <div class="team-select-value">{{ search.domain || $t('page.home.text11') }}<i class="bi bi-chevron-down"></i></div>
                <ul class="sub-menu">
                  <li v-for="(domain,idx) in info.lawyer_where.domain" :key="idx"
                      @click="searchLawyer({domain: domain})">{{ domain.name }}</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4 col-md-6" v-for="(item, index) in info.lawyer" :key="index">
              <div class="team-single-box" @click="$router.push(`/lawyer-detail?id=${item.id}`)">
                <!-- volunteer thumb -->
                <div class="team-thumb">
                  <img :src="item.cover_pic" alt="">
                  <!-- team social icon -->
                  <div class="team-share-social">
                    <div class="team-icon text-right">
                      <span><i class="bi bi-text-center"></i></span>
                    </div>
                  </div>
                </div>
                <!-- team content -->
                <div class="team-content">
                  <!-- volunteer title -->
                  <div class="team-title">
                    <h2>{{ item.name }}</h2>
                  </div>
                  <!-- team text -->
                  <div class="team-text">
                  <span>
                    <li v-for="tag in item.domain">{{ tag }}</li>
                  </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- lawyer button -->
          <div class="lawyer-button text-center" data-aos="fade-up" data-aos-delay="100">
            <a @click="$router.push('/lawyer')"> {{ $t('page.home.查看更多') }} <i class="bi bi-arrow-right"></i></a>
          </div>
        </div>
      </div>
      <!--==================================================-->
      <!-- End lawyer galary Section -->
      <!--==================================================-->


      <!--==================================================-->
      <!-- Start lawyer Blog Section -->
      <!--==================================================-->
      <div class="blog-section">
        <div class="container-fluid">
          <div class="row">
            <div class="col-lg-12">
              <div class="lawyer-section-title text-center pb-50" data-aos="fade-up" data-aos-delay="100" data-aos-anchor-placement="top" >
                <h1><img src="@/assets/scss/page/image/home/icon-1.png">
                  <span class="chinese-text">{{ $t('page.home.text12') }}</span>
                  <span class="english-text">{{ $t('page.home.text13') }}</span>
                </h1>
                <!--              <p>{{ $t('page.home.text14') }}</p>-->
              </div>
            </div>
          </div>
          <div class="row mt-15">
            <OwlCarousel v-if="info.article&& info.article.length>0" class="case-list" :loop="true" :autoplay="true" :nav="false" :dots="false" :margin="30"
                         :center="true"
                         :responsive="{0: {items: 1},768: {items: 3},992: {items: 3},1000: {items: 4},1920: {items: 5}}">
              <div class="col-lg-12" v-for="(item, index) in info.article" :key="index">
                <div class="lawyer-single-blog-box" data-aos="fade-up" data-aos-delay="100" data-aos-anchor-placement="top" >
                  <!-- blog thumb -->
                  <div class="lawyer-blog-thumb">
                    <img :src="item.cover_pic" alt="">
                  </div>
                  <!-- blog content -->
                  <div class="lawyer-blog-content">
                    <!-- post categories -->
                    <div class="post-categories">
                      <p><i class="bi bi-calendar-check"></i> {{ item.created_at }}</p>
                    </div>
                    <!-- blog title -->
                    <div class="blog-title" @click="$router.push(`/news-detail?id=${item.id}`)">
                      <h2><a class="ws-blog"> {{ item.title }} </a></h2>
                    </div>
                  </div>
                </div>
              </div>
            </OwlCarousel>
          </div>
          <!-- lawyer button -->
          <div class="lawyer-button text-center" data-aos="fade-up" data-aos-delay="100" data-aos-anchor-placement="top" >
            <a @click="$router.push('/news')"> {{ $t('page.home.查看更多') }} <i class="bi bi-arrow-right"></i></a>
          </div>
        </div>
      </div>
      <!--==================================================-->
      <!-- Start lawyer Blog Section -->
      <!--==================================================-->


      <!--==================================================-->
      <!-- Start lawyern Testimonial section 名言 -->
      <!--==================================================-->
      <div class="testimonial-section">
        <div class="container">
          <div class="row" data-aos="fade-up">
            <OwlCarousel v-if="info.declaim && info.declaim.length>0" class="testimonial_list" :loop="true" :autoplay="true" :autoplayTimeout="8000" :nav="true"
                         :dots="false"
                         :center="true"
                         :responsive="{0: {items: 1},768: {items: 1},992: {items: 1},1000: {items: 1},1920: {items: 1}}">
              <div v-for="(item,index) in info.declaim" :key="index" class="col-lg-12">
                <div class="testimonial-single-box" data-aos="fade-up" data-aos-delay="100" data-aos-anchor-placement="top" >
                  <!-- testi content -->
                  <div class="testimonial-content">
                    <!-- testi icon -->
                    <div class="testimonial-icon">
                      <i class="bi bi-quote"></i>
                    </div>
                    <!-- testi description -->
                    <div class="testimonial-desc">
                      <p>{{ item.content }}</p>
                    </div>
                    <!-- testi title -->
                    <div class="testi-title">
                      <h4> {{ item.name }}
                        <!--                      <span>-Customer</span>-->
                      </h4>
                    </div>
                  </div>
                  <!-- testi star -->
                  <div class="testi-star-list">
                    <ul>
                      <li><i class="bi bi-star-fill"></i></li>
                      <li><i class="bi bi-star-fill"></i></li>
                      <li><i class="bi bi-star-fill"></i></li>
                      <li><i class="bi bi-star-fill"></i></li>
                      <li><i class="bi bi-star-fill"></i></li>
                    </ul>
                  </div>
                </div>
              </div>
            </OwlCarousel>
          </div>
        </div>
      </div>
      <!--==================================================-->
      <!-- End lawyern Testimonial Area -->
      <!--==================================================-->


      <!--==================================================-->
      <!-- Start lawyer galary Section 荣誉奖项 -->
      <!--==================================================-->
      <div class="case-study-section">
        <div class="container-fluid">
          <div class="row">
            <div class="col-lg-12">
              <div class="lawyer-section-title text-center pb-65" data-aos="fade-up" data-aos-anchor-placement="top" >
                <h1>
                  <img src="@/assets/scss/page/image/home/icon-1.png">
                  <span class="chinese-text">{{ $t('page.home.text15') }}</span>
                  <span class="english-text">{{ $t('page.home.text16') }}</span>
                </h1>
                <!--              <p>{{ $t('page.home.text17') }}</p>-->
              </div>
            </div>
          </div>
          <div class="row" data-aos="fade-up">
            <OwlCarousel v-if="info.lawyer_honor && info.lawyer_honor.length>0" class="case-list" :loop="true" :autoplay="true" :nav="false" :dots="false" :margin="20"
                         :center="true" :autoplayTimeout="2000"
                         :responsive="{0: {items: 1},768: {items: 4},992: {items: 3},1000: {items: 4},1920: {items: 5}}">
              <div class="col-lg-12" v-for="(item,index) in info.lawyer_honor" :key="index">
                <div class="case-study-single-box" data-aos="fade-up" data-aos-delay="100" data-aos-anchor-placement="top" >
                  <!-- study thumb -->
                  <div class="case-study-thumb">
                    <img :src="item.pic_url" alt="">
                    <!-- port content icon -->
                    <div class="case-study-icon">
                      <a class="case-study-icon" @click="currentHonor=item,dialogHonorVisible=true">
                        <i class="bi bi-arrows-fullscreen"></i>
                      </a>
                    </div>
                  </div>
                  <!-- study content -->
                  <div class="case-study-content">
                    <div class="study-text">
                      <p> {{ item.tag || '　' }} </p>
                    </div>
                    <div class="study-title">
                      <h4><a href="case-study-details.html">{{ item.name }}</a></h4>
                    </div>
                  </div>
                </div>
              </div>
            </OwlCarousel>
          </div>
          <!-- lawyer button -->
          <div class="lawyer-button text-center" data-aos="fade-up" data-aos-delay="100">
            <a @click="$router.push('/honor')"> {{ $t('page.home.查看更多') }} <i class="bi bi-arrow-right"></i></a>
          </div>
        </div>
        <el-dialog :visible.sync="dialogHonorVisible">
          <div class="honor-dialog">
            <div class="honor-name">
              <div class="honor-en">{{ currentHonor.name_en }}</div>
              <div class="honor-cn">{{ currentHonor.name }}</div>
            </div>
            <div class="honor-content">
              <div class="honor-img">
                <el-image :src="currentHonor.pic_url" fit="contain"></el-image>
              </div>
              <div v-if="currentHonor.tags.length>0" class="honor-tags">
                <div v-for="item in currentHonor.tags">
                  <i class="bi bi-trophy"></i>
                  <span>{{ item }}</span>
                </div>
              </div>
            </div>
          </div>
        </el-dialog>
      </div>
      <!--==================================================-->
      <!-- End lawyer galary Section -->
      <!--==================================================-->


      <!--==================================================-->
      <!-- Start lawyern Office section 全国分所 -->
      <!--==================================================-->
      <div class="office-section">
        <div class="container-fluid" data-aos="fade-up" data-aos-delay="100" data-aos-anchor-placement="top">
          <div class="row">
            <div class="col-lg-12">
              <div class="lawyer-section-title white pb-60 text-center" >
                <h1>
                  <img src="@/assets/scss/page/image/home/icon-1.png">
                  <span class="chinese-text">{{ $t('page.home.text18') }}</span>
                  <span class="english-text">{{ $t('page.home.text19') }}</span>
                </h1>
                <!--              <p>{{ $t('page.home.text20') }}</p>-->
              </div>
            </div>
          </div>
          <div class="row">
            <OwlCarousel v-if="info.office && info.office.length>0" class="office_list" :loop="true" :autoplay="false" :autoplayTimeout="7000"
                         :dots="false"
                         :center="true"
                         :responsive="{0: {items: 3},768: {items: 5},992: {items: 5},1000: {items: 5},1920: {items: 5}}">
              <div class="col-lg-12" v-for="(item,index) in info.office" :key="index">
                <div class="office-single-box"  :style="`background-image: url(${item.cover_pic});`">
                  <div class="office-content">
                    <div class="office-title">{{ item.name }}</div>
                    <div class="office-text">
                      <div><i class="bi bi-phone"></i> {{ item.mobile }}</div>
                      <div><i class="bi bi-geo-alt-fill"></i> {{ item.address }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </OwlCarousel>
          </div>
          <!-- lawyer button -->
          <div class="lawyer-button text-center" data-aos="fade-up" data-aos-delay="100">
            <a @click="$router.push('/contact')"> {{ $t('page.home.查看更多') }} <i class="bi bi-arrow-right"></i></a>
          </div>
        </div>
      </div>
      <!--==================================================-->
      <!-- End lawyern Testimonial Area -->
      <!--==================================================-->


      <!--==================================================-->
      <!-- Start lawyer service section 组织介绍  -->
      <!--==================================================-->
      <div class="service-section style-two">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="lawyer-section-title pb-60 text-center">
                <h1>
                  <img src="@/assets/scss/page/image/home/icon-1.png">
                  <span class="chinese-text">{{ $t('page.home.text21') }}</span>
                  <span class="english-text">{{ $t('page.home.text22') }}</span>
                </h1>
                <!--              <p>{{ $t('page.home.text23') }}</p>-->
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4 col-md-6">
              <div class="lawyer-service-single-box">
                <!-- service icon -->
                <div class="service-icon">
                  <img src="@/assets/scss/page/image/home/icon-3.png" alt="">
                </div>
                <!-- donate content -->
                <div class="lawyer-service-content">
                  <!-- service title -->
                  <div class="service-title">
                    <h3>{{ $t('page.home.text24') }}</h3>
                  </div>
                  <!-- service description -->
                  <div class="service-desc">
                    <p>{{ $t('page.home.text25') }}</p>
                  </div>
                  <!-- service button -->
                  <div class="service-button">
                    <a @click="$router.push('/about')" style="color: var(--color-main);">{{ $t('page.home.了解更多') }}...</a>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="lawyer-service-single-box">
                <!-- service icon -->
                <div class="service-icon">
                  <img src="@/assets/scss/page/image/home/icon-4.png" alt="">
                </div>
                <!-- donate content -->
                <div class="lawyer-service-content">
                  <!-- service title -->
                  <div class="service-title">
                    <h3>{{ $t('page.home.text26') }}</h3>
                  </div>
                  <!-- service description -->
                  <div class="service-desc">
                    <p>{{ $t('page.home.text27') }}</p>
                  </div>
                  <!-- service button -->
                  <div class="service-button">
                    <a style="color: var(--color-main);" @click="$router.push('/about')">{{ $t('page.home.了解更多') }}...</a>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="lawyer-service-single-box">
                <!-- service icon -->
                <div class="service-icon">
                  <img src="@/assets/scss/page/image/home/icon-5.png" alt="">
                </div>
                <!-- donate content -->
                <div class="lawyer-service-content">
                  <!-- service title -->
                  <div class="service-title">
                    <h3>{{ $t('page.home.text28') }}</h3>
                  </div>
                  <!-- service description -->
                  <div class="service-desc">
                    <p>{{ $t('page.home.text29') }}</p>
                  </div>
                  <!-- service button -->
                  <div class="service-button">
                    <a style="color: var(--color-main);" @click="$router.push('/about')">{{ $t('page.home.了解更多') }}...</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--==================================================-->
      <!-- End lawyer service Secton -->
      <!--==================================================-->
<!--    </div>-->
  </Layout>
</template>

<script>
import Layout from '@/components/common/Layout'
import OwlCarousel from 'vue-owl-carousel'
import {Dialog as ElDialog, Image as ElImage, Message} from "element-ui";


export default {
  name: 'Demo',
  components: {ElImage, ElDialog, Layout, OwlCarousel},
  data() {
    return {
      defaultIcon: require('@/assets/scss/page/image/home/icon-2.png'),
      search: {
        lawyer: '',
        office: '',
        office_id: '',
        domain: '',
        domain_id: '',
      },
      info: {
        about_us: {},
        domain: [],
        lawyer: [],
        lawyer_where: {
          domain: [],
          office: []
        },
        article: [],
        declaim: [],
        lawyer_honor: [],
        office: []
      },
      currentHonor: {
        tags: []
      },
      dialogHonorVisible: false,
    }
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      this.$request({
        url: 'api/pc/index-data',
        data: ''
      }).then(res => {
        // console.log(res)
        this.info = res.data;
      })
    },
    searchLawyer({office, domain}){
      if(office){
        this.search.office = office.name;
        this.search.office_id = office.id;
      }
     if(domain) {
       this.search.domain = domain.name;
       this.search.domain_id = domain.id;
     }

      this.$request({
        url: 'api/pc/query-lawyer',
        data: {
          keyword: this.search.lawyer,
          domain_id: this.search.domain_id,
          office_id: this.search.office_id
        }
      }).then(res => {
        if(!res.data.list || res.data.list.length===0){
          Message.warning('暂无符合的律师');
          return;
        }
          this.info.lawyer = res.data.list;
      })
    }
  }
}
</script>
<!--<style lang="scss" scoped>-->
<!--.demo-page {-->

<!--}-->
<!--@media (min-width: 992px) and (max-width: 2560px) {-->
<!--  .demo-page {-->
<!--    zoom: .8;-->
<!--  }-->
<!--}-->
<!--</style>-->
