<template>
  <Layout>
    <!--==================================================-->
    <!-- Start lawyern breatcam section  -->
    <!--==================================================-->
    <div class="breatcam-section d-flex align-items-center">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="breatcam-content text-center">
              <!-- breadcumb title -->
              <!--              <div class="breatcam-title">-->
              <!--                <h1> {{ $t('header.nav.news') }} </h1>-->
              <!--              </div>-->
              <!--              &lt;!&ndash; breatcam menu &ndash;&gt;-->
              <!--              <div class="breatcam-menu">-->
              <!--                <ul>-->
              <!--                  <li><a href="" @click="$router.push('/')"> {{ $t('header.nav.home') }} </a></li>-->
              <!--                  <li> {{ $t('header.nav.news') }}</li>-->
              <!--                </ul>-->
              <!--              </div>-->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--==================================================-->
    <!-- End lawyern breatcam section -->
    <!--==================================================-->


    <!--==================================================-->
    <!-- Start lawyer service section  -->
    <!--==================================================-->
    <div class="news-title-section">
      <div class="container">
        <h1 class="news-title">{{ info.title }}</h1>
        <div class="news-time">{{ info.created_at }}</div>
      </div>
    </div>
    <div >
      <div class="container-fluid">
        <div class="container">
          <div class="container-left">
            <div class="news-detail">
              <div v-html="info.detail">{{ info.detail }}</div>
            </div>
            <div class="news-btn">
              <div class="news-btn-prev" @click="$router.push({name:'NewsDetail',query:{id:info.previous.id}})">
                {{ $t('page.news.text3') }}<span>{{ info.previous.title }}</span></div>
              <div class="news-btn-back" @click="$router.back()">{{ $t('page.news.text4') }}</div>
            </div>
          </div>
          <div class="container-right">
            <div v-if="info.domain_name" class="news-domain-box">
              <div class="news-domain-title">{{ $t('page.news.text1') }}/div>
                <div class="news-domain-list">
                  <div class="news-domain-label">{{ info.domain_name }}</div>
                </div>
              </div>
              <div class="news-lawyer">
                <div class="news-lawyer-title">{{ $t('page.news.text2') }}</div>
                <div class="news-lawyer-list">
                  <div class="news-lawyer-item" v-for="(item,index) in info.lawyer" :key="index"
                       @click="$router.push({name:'LawyerDetail',query:{id:item.id}})">
                    <div class="news-lawyer-item-img">
                      <img :src="item.image_pic" alt="">
                    </div>
                    <div class="news-lawyer-item-detail">
                      <div class="news-lawyer-item-name">
                        <span>{{ item.name }}</span>{{ item.address }}
                      </div>
                      <div class="news-lawyer-item-desc">{{ item.domain }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
      <!--==================================================-->
      <!-- End lawyer service Section -->
      <!--==================================================-->
  </Layout>
</template>

<script>
import Layout from '@/components/common/Layout'

export default {
  name: 'Demo',
  components: {
    Layout
  },
  data() {
    return {
      id: '',
      info: {}
    }
  },
  created() {
    this.id = this.$route.query.id;
    this.getData();
  },
  methods: {
    getData() {
      this.$request({
        url: 'api/pc/article-detail',
        data: {id: this.id}
      }).then(res => {
        this.info = res.data
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.breatcam-section {
  background-image: url(~@/assets/scss/page/image/news/bg-detail.jpg);
}

.news-title-section {
  height: 180px;
  margin-top: -180px;

  .news-title {
    padding-bottom: 18px;
    margin-bottom: 40px;
    border-bottom: 1px solid white;
    color: white;
  }

  .news-time {
    color: white;
  }
}

.container-fluid {
  background: linear-gradient(to right, #ffffff 50%, #F7F7FB 50%);

  .container {
    display: flex;

    .container-left {
      padding: 70px 0;
      flex: 1;
      background: white;
      min-height: 800px;
      padding-right: 68px;

      .news-detail {
        width: 100%;
      }

      .news-btn {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 50px;

        .news-btn-prev {
          color: #555;
          cursor: pointer;

          span {
            color: var(--color-main);
          }
        }

        .news-btn-back {
          padding: 4px 30px;
          border: 1px solid #555555;
          border-radius: 4px;
          font-size: 14px;
          cursor: pointer;
        }
      }

    }

    .container-right {
      padding-top: 70px;
      padding-left: 50px;
      width: 378px;

      .news-domain-box {
        margin-bottom: 40px;

        .news-domain-title {
          font-size: 20px;
          font-weight: 700;
          margin-bottom: 30px;
        }

        .news-domain-list {
          display: flex;
        }

        .news-domain-label {
          padding: 5px 30px;
          border: 1px solid #555555;
          border-radius: 4px;
        }
      }

      .news-lawyer {
        .news-lawyer-title {
          font-size: 20px;
          font-weight: 700;
          margin-bottom: 30px;
          padding-bottom: 15px;
          border-bottom: 1px solid #E6E6E6;
        }

        .news-lawyer-list {
          .news-lawyer-item {
            display: flex;
            margin-bottom: 30px;
            border: 1px solid #E6E6E6;
            border-bottom: 2px solid var(--color-main);
            border-radius: 4px;
            background-color: white;

            .news-lawyer-item-img {
              width: 105px;
              height: 120px;
              object-fit: contain;
              overflow: hidden;

              img {
                width: 105px;
                height: 120px;
              }
            }

            .news-lawyer-item-detail {
              flex: 1;
              padding: 20px 17px 0 17px;
              color: #555555;

              .news-lawyer-item-name {
                span {
                  font-size: 20px;
                  color: #191514;
                  margin-right: 10px;
                }
              }

              .news-lawyer-item-desc {
                margin-top: 10px;
                line-height: 1.4;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                -webkit-line-clamp: 2;
                line-clamp: 2;
              }
            }
          }
        }
      }
    }
  }


}
</style>
