<template>
  <Layout>
    <!--==================================================-->
    <!-- Start lawyern breatcam section  -->
    <!--==================================================-->
    <div class="breatcam-section d-flex align-items-center">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="breatcam-content text-center">
              <!-- breadcumb title -->
              <div class="breatcam-title">
                <h1> {{ $t('header.nav.join') }} </h1>
              </div>
              <!-- breatcam menu -->
<!--              <div class="breatcam-menu">-->
<!--                <ul>-->
<!--                  <li><a @click="$router.push('/')"> {{ $t('header.nav.home') }} </a></li>-->
<!--                  <li> {{ $t('header.nav.join') }}</li>-->
<!--                </ul>-->
<!--              </div>-->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--==================================================-->
    <!-- End lawyern breatcam section -->
    <!--==================================================-->


    <!--==================================================-->
    <!-- Start lawyer service section  -->
    <!--==================================================-->

    <div class="service-section">
      <div class="container">
        <div class="search-box">
          <div class="search-input">
            <el-input v-model="search.keyword" class="search-el-input" :placeholder="$t('page.join.text1')" size="large" @change="getData"></el-input>
            <div class="search-input-icon"><i class="bi bi-search"></i></div>
          </div>
          <div class="search-selects">
            <el-select v-model="search.city" :placeholder="$t('page.join.text2')" clearable filterable @change="getData">
              <el-option
                  v-for="item in searchOptions.city"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
              </el-option>
            </el-select>
            <el-select v-model="search.position" :placeholder="$t('page.join.text3')" clearable filterable @change="getData">
              <el-option
                  v-for="item in searchOptions.position"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
              </el-option>
            </el-select>
            <el-select v-model="search.type" :placeholder="$t('page.join.text4')" clearable filterable @change="getData">
              <el-option
                  v-for="item in searchOptions.type"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <div class="join-single-box" v-for="(item, index) in list" :key="index" @click="$router.push(`/join-detail?id=${item.id}`)">
              <div class="join-left">
                <div class="join-title">{{ item.position }} （{{item.type}}）</div>
                <div class="join-time">{{$t('page.join.text5')}}{{ item.updated_at }}</div>
                <div>
                  <pre>{{item.intro}}</pre>
                </div>
              </div>
              <div class="join-right">
                <div class="join-locate">
                  <i class="bi bi-geo-alt"></i>
                  <div>{{ item.city }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="text-center">
          <el-pagination
              layout="prev, pager, next"
              :current-page="search.page"
              :page-size="search.limit"
              @current-change="handleCurrentChange"
              :total="total">
          </el-pagination>
        </div>
      </div>
    </div>

    <!--==================================================-->
    <!-- End lawyer service Section -->
    <!--==================================================-->
  </Layout>
</template>

<script>
import Layout from '@/components/common/Layout'
import { Pagination, Button, Input, Select, Option } from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

export default {
  name: 'Demo',
  components: {Layout,
    ElPagination: Pagination,
    ElButton: Button,
    ElInput: Input,
    ElSelect: Select,
    ElOption: Option,},
  data() {
    return {
      defaultIcon: require('@/assets/scss/page/image/home/icon-2.png'),
      letters: ['全部', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'],
      search: {
        keyword: '',
        city: '',
        type: '',
        page: 1,
        limit: 10,
      },
      searchOptions: {
        position: [],
        city: [],
        type: [],
      },
      list: [],
      total: 0,
    }
  },
  created() {
    this.getOptions();
    this.getData();
  },
  methods: {
    selectLetter(letter) {
      this.search.letter = letter;
      this.getData();
    },
    getOptions() {
      this.$request({
        url: 'api/pc/recruit-where',
        data: ''
      }).then(res => {
        this.searchOptions = res.data;
      })
    },
    getData() {
      this.$request({
        url: 'api/pc/recruit-list',
        data: this.search
      }).then(res => {
        this.list = res.data.list;
        this.total = res.data.pagination.totalCount;
      })
    },
    handleCurrentChange(e) {
      this.search.page = e;
      this.getData();
    }
  }
}
</script>
<style lang="scss" scoped>
.breatcam-section {
  background-image: url(~@/assets/scss/page/image/join/bg.jpg);
}
.service-section {
  background: url(~@/assets/scss/page/image/join/bg-2.jpg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  min-height: 800px;
}
.search-box{

  .search-input{
    border: 6px solid var(--color-main);
    border-radius: 2px;
    display: flex;
    align-items: center;
    background: var(--color-main);
    .search-el-input{
    }

    .search-input-icon{
      font-size: 30px;
      padding: 15px 25px;
      color: white;
    }
  }

  .search-selects{
    display: flex;
    align-items: center;
    margin: 30px 0 50px;
    .el-select{
      flex: 1;
      margin-right: 30px;
      background: #B59A6C;
      border: 3px solid var(--color-main);
      border-radius: 2px;
      font-size: 15px;

      &:last-child{
        margin-right: 0;
      }
    }
  }
}
.join-single-box{
  display: flex;
  align-items: center;
  background: #FFFFFF;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.18);
  border-radius: 4px;
  padding: 30px 110px 30px 30px;
  margin-bottom: 30px;
  .join-left{
    flex: 1;
    .join-title{
      font-size: 24px;
      color: #191514;
    }
    .join-time{
      margin: 9px 0 18px 0;
    }
  }
  .join-right{
    text-align: center;
    font-size: 18px;
    color: #555555;
  }
}

</style>
