<template>
  <Layout>
    <!--==================================================-->
    <!-- Start lawyern breatcam section  -->
    <!--==================================================-->
    <div class="service-detail breatcam-section d-flex align-items-center">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="breatcam-content text-center">
              <!-- breadcumb title -->
              <div class="breatcam-title">
                <h1> {{ $t('header.nav.businessAreas') }} </h1>
              </div>
              <!-- breatcam menu -->
<!--              <div class="breatcam-menu">-->
<!--                <ul>-->
<!--                  <li><a @click="$router.push('/')"> {{ $t('header.nav.home') }} </a></li>-->
<!--                  <li> {{ $t('header.nav.businessAreas') }}</li>-->
<!--                </ul>-->
<!--              </div>-->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--==================================================-->
    <!-- End lawyern breatcam section -->
    <!--==================================================-->


    <!--==================================================-->
    <!-- Start lawyer service section  -->
    <!--==================================================-->

    <div class="service-section">
      <div class="container">
        <OwlCarousel v-if="list && list.length>0" ref="carousel" class="service-detail-list" :loop="false" :autoplay="false"
                     :autoplayTimeout="8000" :nav="true"
                     :dots="false"
                     :center="true"
                     :responsive="{0: {items: 1, currentItem: currentIdx},768: {items: 1},992: {items: 1},1000: {items: 1},1920: {items: 1}}"
                      >
          <div class="col-lg-12" v-for="(info, index) in list" :key="index">
            <div class="row">
              <div class="col-lg-12">
                <div class="lawyer-section-title pb-60 text-center pt-30" data-aos="fade-up">
                  <h1>
                    <img src="@/assets/scss/page/image/home/icon-1.png">
                    <span class="chinese-text">{{ $localeLang == 'zh' ? info.name : info.name_en }}</span>
                    <span class="english-text">{{ $localeLang == 'zh' ? info.name_en : info.name }}</span>
                  </h1>
<!--                  <p>{{ info.intro }}</p>-->
                </div>
              </div>
            </div>
            <div class="service-detail-single-box row" data-aos="fade-up">
              <div class="col-lg-5 col-md-12">
                <div class="service-detail-thumb">
                  <img class="service-detail-thumb-img" src="@/assets/scss/page/image/service/img-1.jpg" alt="">
                  <img class="service-detail-thumb-icon" :src="info.icon || defaultIcon" alt="">
                </div>
              </div>
              <div class="col-lg-7 col-md-12">
                <div class="service-detail-content">
                  <div v-html="info.pc_detail">{{ info.pc_detail }}</div>
                </div>
              </div>
            </div>
          </div>
        </OwlCarousel>
      </div>
    </div>

    <!--==================================================-->
    <!-- End lawyer service Section -->
    <!--==================================================-->
  </Layout>
</template>

<script>
import Layout from '@/components/common/Layout'
import OwlCarousel from "vue-owl-carousel";

export default {
  name: 'Demo',
  components: {Layout, OwlCarousel},
  data() {
    return {
      id: '',
      defaultIcon: require('@/assets/scss/page/image/home/icon-2.png'),
      list: {}
    }
  },
  created() {
    this.id = this.$route.query.id;
    this.getData();
  },
  methods: {
    getData() {
      this.$request({
        url: 'api/pc/domain-list',
        data: {id: this.id}
      }).then(res => {
        let temp = res.data;
        // 找到与this.id匹配的元素的索引
        const idx = temp.findIndex((item) => item.id == this.id);
        // console.log(idx)
        if (idx !== -1) {
          // 如果找到了匹配的元素
          // 将匹配元素之前的所有元素切片出来
          const elementsBefore = temp.slice(0, idx);
          // 剩余的元素
          const elementsAfter = temp.slice(idx);

          // 合并数组，将匹配元素及其后的元素放在前面，之前的元素放在后面
          this.list = [...elementsAfter, ...elementsBefore];
        }
      })
    }
  }
}
</script>
