<template>
  <div >
    <!-- Start Header Area -->
    <header :class="[`rn-header header-default header-not-transparent header-sticky lawyer_nav_manu align-items-center`]">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-2">
            <div class="logo">
              <a class="logo_img" href="" title="lawyer">
                <img src="@/assets/images/logo/logo.png" alt="">
              </a>
              <a class="main_sticky" href="" title="lawyer">
                <img src="@/assets/images/logo/logo.png" alt="astute">
              </a>
            </div>
          </div>
          <div class="col-lg-10">
            <Nav></Nav>
          </div>
<!--          <div class="col-lg-2 pl-0 pr-0 ">-->
<!--            <div class="header-button">-->
<!--              <a href="contact.html"> Gete Started <i class="bi bi-arrow-right"></i> </a>-->
<!--            </div>-->
<!--          </div>-->
        </div>
      </div>
    </header>
    <!-- End Header Area -->

    <!-- Start Mobile Menu -->
    <MobileMenu/>
    <!-- End Mobile Menu -->

    <!-- Start Theme Style -->
    <div>
      <div class="rn-gradient-circle"/>
      <div class="rn-gradient-circle theme-pink"/>
    </div>
    <!-- End Theme Style -->
  </div>
</template>

<script>
import Icon from "@/components/icon/Icon.vue";
import MobileMenu from "@/components/common/header/MobileMenu.vue";
import AppFunctions from "@/helpers/AppFunctions";
import Nav from "@/components/common/header/Nav.vue";
import Logo from "@/components/elements/logo/Logo.vue";

export default {
  name: 'Header',
  props: {
    data: {
      default: null
    }
  },
  components: {Logo, Nav, MobileMenu, Icon},
  data() {
    return {
      AppFunctions
    }
  },
  methods: {
    toggleStickyHeader() {
      const scrolled = document.documentElement.scrollTop;
      if (scrolled > 100) {
        AppFunctions.addClass('.header-default', 'sticky');
      } else if (scrolled <= 100) {
        AppFunctions.removeClass('.header-default', 'sticky');
      }
    }
  },
  created() {
    window.addEventListener('scroll', this.toggleStickyHeader);
  },
  mounted() {
    this.toggleStickyHeader();
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.toggleStickyHeader);
  }
}
</script>
