<template>

      <div class="scroll-area">
        <div class="top-wrap">
          <div class="go-top-btn-wraper">
            <div :class="`go-top ${visible?'active':''} go-top-button`" @click="scrollToTop">
              <i class="bi bi-chevron-up"></i>
              <i class="bi bi-chevron-up"></i>
            </div>
          </div>
        </div>
      </div>
</template>

<script>
    import Icon from "@/components/icon/Icon.vue";

    export default {
        name: 'ScrollTop',
        components: {Icon},
        data() {
            return {
                visible: false
            }
        },
        methods: {
            scrollToTop() {
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                });
            },
            toggleVisible() {
                const scrolled = document.documentElement.scrollTop;
                if (scrolled > 300) {
                    this.visible = true;
                } else if (scrolled <= 300) {
                    this.visible = false;
                }
            }
        },
        created() {
            window.addEventListener('scroll', this.toggleVisible);
        },
        mounted() {
            this.toggleVisible();
        },
        beforeDestroy() {
            window.removeEventListener('scroll', this.toggleVisible);
        }
    }
</script>
