<template>
  <Layout>
    <!--==================================================-->
    <!-- Start lawyern breatcam section  -->
    <!--==================================================-->
    <div class="breatcam-section d-flex align-items-center">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="breatcam-content text-center">
              <!-- breadcumb title -->
              <div class="breatcam-title">
                <h1> {{ $t('header.nav.contact') }} </h1>
              </div>
              <!-- breatcam menu -->
<!--              <div class="breatcam-menu">-->
<!--                <ul>-->
<!--                  <li><a @click="$router.push('/')"> {{ $t('header.nav.home') }} </a></li>-->
<!--                  <li> {{ $t('header.nav.contact') }}</li>-->
<!--                </ul>-->
<!--              </div>-->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--==================================================-->
    <!-- End lawyern breatcam section -->
    <!--==================================================-->


    <!--==================================================-->
    <!-- Start lawyer service section  -->
    <!--==================================================-->

    <div class="service-section">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="contact-single-box" v-for="(item, index) in list" :key="index">
              <div class="contact-left">
                <div class="contact-title">{{ item.name }}</div>
                <div class="contact-info">
                  <i class="bi bi-phone-fill"></i>
                  <span>{{ item.mobile }}</span>
                </div>
                <div class="contact-info">
                  <i class="bi bi-envelope-fill"></i>
                  <span>{{ item.email || '--' }}</span>
                </div>
                <div class="contact-info">
                  <i class="bi bi-geo-alt-fill"></i>
                  <span>{{ item.address }}</span>
                </div>
              </div>
              <div class="contact-line"></div>
              <div class="contact-right">
                <div class="contact-locate">
                  <div :id="`map_${index}`"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--==================================================-->
    <!-- End lawyer service Section -->
    <!--==================================================-->
  </Layout>
</template>

<script>
import Layout from '@/components/common/Layout'

export default {
  name: 'Demo',
  components: {Layout},
  data() {
    return {
      locateImg: require('@/assets/scss/page/image/common/locate.png'),
      list: []
    }
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.$request({
        url: 'api/pc/office-list',
        data: this.search
      }).then(res => {
        this.list = res.data.list;
        setTimeout(() => {
          for(let i = 0; i < this.list.length; i++){
            this.initMap(i)
          }
        }, 300)
      })
    },
    initMap(index) {
      // 定义map变量，调用 TMap.Map() 构造函数创建地图
      let map = new window.qq.maps.Map(document.getElementById(`map_${index}`), {
        center:  new window.qq.maps.LatLng(this.list[index].latitude, this.list[index].longitude), // 设置地图中心点坐标
        zoom: 17, // 设置地图缩放级别
      });
      let anchor = new window.qq.maps.Point(9, 9),
          size = new window.qq.maps.Size(36, 36),
          origin = new window.qq.maps.Point(0, 0),
          icon = new window.qq.maps.MarkerImage(this.locateImg, size, origin, anchor);
      let marker = new window.qq.maps.Marker({
        icon: icon,
        map: map,
        position:map.getCenter()});
    }
  }
}
</script>
<style lang="scss" scoped>
.breatcam-section {
  background-image: url(~@/assets/scss/page/image/contact/bg.jpg);
}
.contact-single-box{
  display: flex;
  align-items: center;
  margin-bottom: 60px;
  .contact-left{
    width: 32.5%;
    padding: 0 50px;
    .contact-title{
      color: var(--color-main);
      font-size: 18px;
      font-weight: 600;
      padding-bottom: 22px;
      border-bottom: 1px solid #E6E6E6;
      margin-bottom: 28px;
    }
    .contact-info{
      i{
        margin-right: 20px;

      }
      padding-bottom: 20px;
    }
  }
  .contact-line{
    background: linear-gradient(270deg, rgba(181, 154, 108, 0.31) 0%, rgba(181, 154, 108, 0) 100%);
    width: 24px;
    height: 357px;
  }
  .contact-right{
    flex: 1;
    .contact-locate{
      width: 100%;
      >div{
        height: 357px;
        margin-left: 70px;
      }
    }
  }
}
</style>
