<template>
  <Layout>
    <!--==================================================-->
    <!-- Start lawyern breatcam section  -->
    <!--==================================================-->
    <div class="lawyer breatcam-section d-flex align-items-center">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="breatcam-content text-center">
              <!-- breadcumb title -->
              <div class="breatcam-title">
                <h1> {{ $t('header.nav.lawyerTeam') }} </h1>
              </div>
              <!-- breatcam menu -->
<!--              <div class="breatcam-menu">-->
<!--                <ul>-->
<!--                  <li><a @click="$router.push('/')"> {{ $t('header.nav.home') }} </a></li>-->
<!--                  <li> {{ $t('header.nav.lawyerTeam') }} </li>-->
<!--                </ul>-->
<!--              </div>-->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--==================================================-->
    <!-- End lawyern breatcam section -->
    <!--==================================================-->



    <!--==================================================-->
    <!-- Start lawyer service section  -->
    <!--==================================================-->
    <div class="service-section">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="lawyer-section-title pb-60 text-center" data-aos="fade-up">
              <h1>
                <img src="@/assets/scss/page/image/home/icon-1.png">
                <span class="chinese-text">{{ $t('page.lawyer.text1') }}</span>
                <span class="english-text">{{ $t('page.lawyer.text2') }}</span>
              </h1>
<!--              <p>{{ $t('page.home.text5') }}</p>-->
            </div>
          </div>
        </div>
        <div class="letter-list">
          <div v-for="(letter, index) in letters" :key="index" class="letter-item"
               :class="{ active: search.letter === letter }"
               @click="selectLetter(letter)">
            {{ letter }}
          </div>
        </div>
<!--        <div class="search-box">-->
<!--          <el-input v-model="search.name" :placeholder="$t('page.lawyer.text4')" @change="getData"></el-input>-->
<!--          <el-select v-model="search.office_id" :placeholder="$t('page.lawyer.text5')" clearable filterable @change="getData">-->
<!--            <el-option-->
<!--                v-for="item in searchOptions.office"-->
<!--                :key="item.id"-->
<!--                :label="item.name"-->
<!--                :value="item.id">-->
<!--            </el-option>-->
<!--          </el-select>-->
<!--          <el-select v-model="search.domain_id" :placeholder="$t('page.lawyer.text6')" clearable filterable @change="getData">-->
<!--            <el-option-->
<!--                v-for="item in searchOptions.domain"-->
<!--                :key="item.id"-->
<!--                :label="item.name"-->
<!--                :value="item.id">-->
<!--            </el-option>-->
<!--          </el-select>-->
<!--          <el-button type="primary" icon="el-icon-search" @click="getData"></el-button>-->
<!--        </div>-->
        <div class="row search-box">
          <div class="search-box-item">
            <div class="team-search" data-aos="fade-up" data-aos-delay="100">
              <input v-model="search.keyword" :placeholder="$t('page.home.text9')" @change="getData">
            </div>
          </div>
          <div class="search-box-item">
            <div class="team-select" data-aos="fade-up" data-aos-delay="100">
              <div class="team-select-value">{{ search.office || $t('page.home.text10') }}<i class="bi bi-chevron-down"></i></div>
              <ul class="sub-menu">
                <li @click="search.office_id='',search.office='',getData()">全部律所</li>
                <li v-for="(office,idx) in searchOptions.office" :key="idx"
                    @click="search.office_id=office.id,search.office=office.name,getData()">{{ office.name }}</li>
              </ul>
            </div>
          </div>
          <div class="search-box-item">
            <div class="team-select" data-aos="fade-up" data-aos-delay="100">
              <div class="team-select-value">{{ search.domain || $t('page.home.text11') }}<i class="bi bi-chevron-down"></i></div>
              <ul class="sub-menu">
                <li @click="search.domain_id='',search.domain='',getData()">全部业务领域</li>
                <li v-for="(domain,idx) in searchOptions.domain" :key="idx"
                    @click="search.domain_id=domain.id,search.domain=domain.name,getData()">{{ domain.name }}</li>
              </ul>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-4 col-md-6" v-for="(item, index) in list" :key="index">
            <div class="lawyer-single-box" data-aos="fade-up" data-aos-delay="100" @click="$router.push(`/lawyer-detail?id=${item.id}`)">
              <div class="lawyer-image"><el-image :src="item.cover_pic" fit="fill"></el-image></div>
              <div class="lawyer-content">
                <div class="lawyer-name"><h4>{{ item.name }}</h4></div>
                <div class="lawyer-locate">{{ item.address }}</div>
                <div>{{ $t("header.nav.businessAreas") }}</div>
                <div class="lawyer-domain">{{ item.domain }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="text-center">
          <el-pagination
              layout="prev, pager, next"
              :current-page="search.page"
              :page-size="search.limit"
              @current-change="handleCurrentChange"
              :total="total">
          </el-pagination>
        </div>
      </div>
    <!--==================================================-->
    <!-- End lawyer service Section -->
    <!--==================================================-->
  </Layout>
</template>

<script>
import Layout from '@/components/common/Layout'
import { Pagination, Button, Input, Select, Option, Image } from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

export default {
  name: 'Demo',
  components: {
    Layout,
    ElPagination: Pagination,
    ElButton: Button,
    ElInput: Input,
    ElSelect: Select,
    ElOption: Option,
    ElImage: Image
  },
  data() {
    return {
      loading: false,
      defaultIcon: require('@/assets/scss/page/image/home/icon-2.png'),
      letters: ['全部', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'],
      search: {
        letter: '全部',
        keyword: '',
        domain_id: '',
        office_id: '',
        page: 1,
        limit: 9,
      },
      searchOptions: {
        domain: [],
        job: [],
        office: [],
      },
      list: [],
      total: 0,
    }
  },
  created() {
    this.getOptions();
    this.getData();
  },
  methods: {
    selectLetter(letter) {
      this.search.letter = letter;
      this.getData();
    },
    getOptions() {
      this.$request({
        url: 'api/pc/lawyer-where',
        data: ''
      }).then(res => {
        this.searchOptions = res.data;
      })
    },
    getData() {
      this.loading = true;
      this.$request({
        url: 'api/pc/lawyer-list',
        data: this.search
      }).then(res => {
        this.loading = false;
        this.list = res.data.list;
        this.total = res.data.pagination.totalCount;
      })
    },
    handleCurrentChange(e) {
      this.search.page = e;
      this.getData();
    }
  }
}
</script>
<style lang="scss" scoped>
.lawyer.breatcam-section {
  background-image: url(~@/assets/scss/page/image/lawyer/bg.jpg);
}
.letter-list{
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 40px;
}
.letter-item {
  flex: 1;
  text-align: center;
  cursor: pointer;
  color: var(--color-gray);
  transition: all 0.3s ease;
  cursor: pointer;
}

.letter-item.active {
  background-color: var(--color-main);
  color: white;
  border-radius: 5px;
}
.search-box{
  display: flex;
  align-items: center;
  justify-content: space-between;
  //margin-bottom: 40px;

  .search-box-item{
    flex: 1;
  }

  .team-select-value{
    background: white;
    width: 100%;
  }
  .team-search input{
    background: white;
    width: 100%;
  }

  .team-select .sub-menu{
    background: white;
    max-height: 400px;
    overflow-y: auto;
    width: 100%;
    left: 0;
    li{
      color: #333!important;
      &:hover{
        color: white!important;
      }
    }
  }
  .el-input{
    width: 30%;
  }
  .el-select{
    width: 30%;
  }
  .el-button{
    width: 7%;
    background: var(--color-main);
    border-color: var(--color-main);
  }
}
.el-select-dropdown__item.selected{
  color: var(--color-main);
}

.lawyer-single-box{
  width: 100%;
  display: flex;
  transition: .6s;
  margin-bottom: 30px;
  cursor: pointer;
  border: 1px solid #F7F7FB;
  border-radius: 4px;
  .lawyer-image{
    width: 50%;
    height: 100%;
    overflow: hidden;
    display: flex;
    .el-image{
      width: 100%;
      height: 100%;
      transition: all 0.3s ease;
      img{
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
  .lawyer-content{
    width: 50%;
    background: #F7F7FB;
    font-size: 14px;
    color: #555555;
    text-align: center;
    line-height: 1;
    padding: 2%;
    display: flex;
    flex-direction: column;
    transition: all 0.3s ease;
    font-weight: 300;

    .lawyer-name{
      padding: 10% 0 12% 0;
    }
    .lawyer-locate{
      flex: 1;
      font-weight: 300;
    }
    .lawyer-domain{
      margin-top: 5%;
      border-top: 1px solid #E6E6E6;
      padding-top: 4%;
      line-height: 1.2;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      overflow: hidden;
      font-weight: 300;
    }
  }

  &:hover{

    .lawyer-image{
      img{
        transform: scale(1.1);
      }
    }
    .lawyer-content{
      background: var(--color-main);
      color: white;
      .lawyer-name h4{
        color: white;
      }
      .lawyer-domain{
        border-top: 1px solid #ffffff;
      }
    }
  }
}
</style>
