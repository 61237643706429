<template>
  <nav class="lawyer_menu">
    <ul class="nav_scroll">
      <li><a @click="$router.push('/')">{{ $t('header.nav.home') }}</a></li>
      <li><a @click="$router.push('/service')">{{ $t('header.nav.businessAreas') }} </a></li>
      <li><a @click="$router.push('/lawyer')">{{ $t('header.nav.lawyerTeam') }} </a></li>
      <li><a href="#">{{ $t('header.nav.news') }}</a>
        <ul class="sub-menu">
          <li><a @click="$router.push('/news?type=1')">{{ $t('header.nav.junYanNews') }}</a></li>
          <li><a @click="$router.push('/news?type=2')">{{ $t('header.nav.honors') }}</a></li>
          <li><a @click="$router.push('/news?type=3')">{{ $t('header.nav.perspectives') }}</a></li>
          <li><a @click="$router.push('/news?type=4')">{{ $t('header.nav.renwen') }}</a></li>
<!--          <li><a @click="$router.push('/news?tab=5')">{{ $t('header.nav.achievements') }}</a></li>-->
        </ul>
      </li>
      <li><a @click="$router.push('/about')">{{ $t('header.nav.about') }}</a></li>
      <li><a @click="$router.push('/join')">{{ $t('header.nav.join') }}</a></li>
      <li><a @click="$router.push('/contact')">{{ $t('header.nav.contact') }}</a></li>
    </ul>
    <span class="globe-btn">
      <span v-if="$localeLang === 'zh'" @click="translate('en')"><i class="bi bi-globe-asia-australia"></i> EN</span>
      <span v-else @click="translate('zh')"><i class="bi bi-globe-americas"></i> CN</span>
    </span>
  </nav>
</template>

<script>
import Cookies from 'js-cookie';

export default {
  name: 'Nav',
  methods: {
    translate(lang) {
      this.$i18n.locale = lang
      this.$localeLang = lang
      Cookies.set('localeLang', lang)
      // 刷新网页
      location.reload();
      if (lang === 'zh') {
        document.documentElement.style.setProperty('--main-font', `"Microsoft YaHei", Arial, sans-serif`);
      } else if (lang === 'en') {
        document.documentElement.style.setProperty('--main-font', `"Mulish", sans-serif`);
      }
    }
  }
}
</script>
