<template>
  <Layout>
    <!--==================================================-->
    <!-- Start lawyern breatcam section  -->
    <!--==================================================-->
    <div class="breatcam-section d-flex align-items-center">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="breatcam-content text-center">
              <!-- breadcumb title -->
              <div class="breatcam-title">
                <h1> {{ $t('header.nav.honors') }} </h1>
              </div>
              <!-- breatcam menu -->
<!--              <div class="breatcam-menu">-->
<!--                <ul>-->
<!--                  <li><a @click="$router.push('/')"> {{ $t('header.nav.home') }} </a></li>-->
<!--                  <li> {{ $t('header.nav.honors') }}</li>-->
<!--                </ul>-->
<!--              </div>-->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--==================================================-->
    <!-- End lawyern breatcam section -->
    <!--==================================================-->


    <!--==================================================-->
    <!-- Start lawyer galary Section -->
    <!--==================================================-->
    <div class="case-study-section upper">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="lawyer-section-title text-center">
              <h1>
                <img src="@/assets/scss/page/image/home/icon-1.png">
                <span class="chinese-text">{{ $t('page.home.text15') }}</span>
                <span class="english-text">{{ $t('page.home.text16') }}</span>
              </h1>
              <p>{{ $t('page.home.text17') }}</p>
            </div>
          </div>
        </div>
        <div class="row image_load mt-70">
          <div class="col-lg-4 col-md-6 grid-item physics english boilogy" v-for="item in list" :key="item.id">
            <div class="case-study-single-box">
              <!-- study thumb -->
              <div class="case-study-thumb">
                <img :src="item.pic_url" alt="">
                <!-- port content icon -->
                <div class="case-study-icon">
                  <a class="case-study-icon" @click="current=item,dialogVisible=true"><i class="bi bi-arrows-fullscreen"></i></a>
                </div>
                <!-- study content -->
                <div class="case-study-content">
                  <div class="study-text">
                    <p> {{ item.tag || '　' }} </p>
                  </div>
                  <div class="study-title">
                    <h4><a>{{ item.name }}</a></h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <el-dialog :visible.sync="dialogVisible">
        <div class="honor-dialog">
          <div class="honor-name">
            <div class="honor-en">{{ current.name_en }}</div>
            <div class="honor-cn">{{ current.name }}</div>
          </div>
          <div class="honor-content">
            <div class="honor-img">
              <el-image :src="current.pic_url" fit="contain"></el-image>
            </div>
            <div v-if="current.tags.length>0" class="honor-tags">
              <div v-for="item in current.tags">
                <i class="bi bi-trophy"></i>
                <span>{{ item }}</span>
              </div>
            </div>
          </div>
        </div>
      </el-dialog>
    </div>
    <!--==================================================-->
    <!-- End lawyer galary Section -->
    <!--==================================================-->
  </Layout>
</template>

<script>
import Layout from '@/components/common/Layout'
import {Image, Dialog} from "element-ui";
import 'element-ui/lib/theme-chalk/index.css';

export default {
  name: 'Demo',
  components: {Layout, ElImage: Image, ElDialog: Dialog},
  data() {
    return {
      list: [],
      dialogVisible: false,
      current: {
        tags:[]
      }
    }
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      this.$request({
        url: 'api/pc/honor-list',
      }).then(res => {
        this.list = res.data;
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.breatcam-section {
  background-image: url(~@/assets/scss/page/image/honor/bg.jpg);
}
</style>
