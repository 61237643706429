export default {
  "title": "Guangdong JunYan Law Firm",
  header: {
    nav: {
      home: "Home",
      businessAreas: "Business",
      lawyerTeam: "Team",
      news: "News",
      about: "About",
      join: "Join Us",
      contact: "Contacts",
      junYanNews: "News",
      honors: 'Honors',
      perspectives: "Perspectives",
      lectureHall: "Lecture Hall",
      achievements: "Achievements"
    }
  },
  footer: {
    phone: 'Phone: ',
    email: 'Email: ',
    copyright: 'Copyright',
    copyrightText: 'Copyright © 2024 JunYan Law Firm. All Rights Reserved 广东君言律师事务所 版权所有 粤ICP备14017639号',
    businessAreas: 'Practice Areas',
    news: 'News',
    about: 'About',
    劳动与就业: 'Labor & Employment',
    知识产权: 'Intellectual Property',
    法律IT服务: 'Legal IT Services',
    银行和金融: 'Banking & Finance',
    民商事争议解决: 'Dispute Resolution & Litigation',
    刑事辩护与刑事合规: 'Criminal Defense & Compliance',
    房地产与建筑工程: 'Real Estate & Construction',
    婚姻家事与财富管理: 'Family Law & Wealth Management',
    新闻资讯: 'News & Information',
    君言业绩: 'JunYan Achievements',
    君言荣誉: 'JunYan Honors',
    君言动态: 'JunYan Dynamics',
    君言讲堂: 'JunYan Lecture Hall',
    君言观点: 'JunYan Perspectives',
    君言人文: 'JunYan Humanities',
    君言简介: 'JunYan Introduction',
    加入君言: 'Join JunYan'
  },
  发现更多: 'Discover More',
  了解更多: 'Read More',
  查看更多: 'Read More',
  业务领域: 'Practice Areas',
  专业团队: 'Professional Team',
  最新资讯: 'Latest News',
  荣誉奖项: 'Honors & Awards',
  全国分所: 'National Office',
  page:{
    home: {
      了解更多: 'Read More',
      查看更多: 'Read More',
      text1: 'Welcome to JunYan Law Firm',
      text2: 'Established on May 26, 2003, with approval from the Department of Justice of Guangdong Province, JunYan Law Firm is a high-caliber and high-standard law firm founded by several like-minded senior lawyers and academic experts. Currently, our firm has over 200 practicing lawyers, legal assistants, and administrative secretaries, occupying an office space of over 2,000 square meters. We have been designated as a teaching and internship base by the School of Law at Peking University and Southwest University of Political Science & Law.',
      text3: 'Practice Areas',
      text4: '业务领域',
      text5: 'Diving deep into the forefront of legal practice, spanning civil, commercial, criminal, and other diverse fields, we deliver excellence through professionalism, offering comprehensive and customized legal solutions to our clients.',
      text6: 'Professional Team',
      text7: '专业团队',
      text8: 'Aggregating the wisdom of elites, co-creating legal brilliance — Our team, your trusted choice.',
      text9: 'Search by Name',
      text10: 'Search by Office',
      text11: 'Search by Practice Area',
      text12: 'Latest News',
      text13: '最新资讯',
      text14: 'Insight into the forefront of legal governance, grasping the pulse of the legal profession — Latest news, presenting industry trends and professional perspectives.',
      text15: 'Honors & Awards',
      text16: '荣誉奖项',
      text17: 'Excellence attested, crowned with glory — Our professionalism and commitment have been repeatedly honored by the industry.',
      text18: 'National Offices',
      text19: '全国分所',
      text20: 'Spanning regions, serving the nation — Branch offices across the country make professional services easily accessible.',
      text21: 'About Us',
      text22: '组织介绍',
      text23: 'Rooted in belief, building the future — Gain a deeper understanding of our organizational structure and culture, together witnessing the power and value of legal services.',
      text24: 'Team Qualifications',
      text25: 'JunYan Law Firm is a large-scale comprehensive law firm located in the Nanshan High-Tech Industrial Park of Shenzhen. After 18 years of development, we have formed a team of lawyers with good professional backgrounds and rich experience.',
      text26: 'Specialization',
      text27: 'Since its inception, JunYan Law Firm has been dedicated to providing high-quality legal services to clients. Through 18 years of practice, our lawyers have developed specialized divisions that are at the forefront of the industry.',
      text28: 'JunYan\'s Mission',
      text29: '"Focus, pragmatism, integrity, and efficiency" are the service tenets of JunYan Law Firm, while "specialized division of labor, team-based operations, and holistic service" are the guiding principles of our service philosophy.'
    },
    about: {
      title: 'About Junyan',
      home: 'Home',
      text1: 'Welcome to',
      text2: 'JunYan Law Firm',
      text3: 'Team Qualifications',
      text4: 'TEAM QUALIFICATIONS',
      text5: 'Specialized Divisions',
      text6: 'PROFESSIONAL DIVISIONS',
      text7: "JunYan’s Mission",
      text8: 'JUNYAN PRINCIPLE',
    },
    lawyer: {
      text1: 'Lawyer Team',
      text2: 'LAWYER TEAM',
      text3: 'Download Resume',
      text4: 'Enter lawyer name to search',
      text5: 'Filter by office location',
      text6: 'Filter by practice area'
    },
    news: {
      text1: 'Relevant Practice Areas',
      text2: 'Relevant Personnel',
      text3: 'Previous Page:',
      text4: 'Return to List',
      text5: 'View Details'
    },
    join: {
      text1: 'Search Job Positions',
      text2: 'Filter by City',
      text3: 'Filter by Job Category',
      text4: 'Filter by Recruitment Type',
      text5: 'Updated on '
    }
  }
}
